import React from 'react';
import { connect } from 'react-redux';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      author: this.props.author || '',
      msg: this.props.msg || '',
    };

    // Bindings
  }

  render() {
    const { player, author, role } = this.props;
    const roles = ["Federico's", 'Zephyr'];

    const mine = player._id === this.state.author ? true : false;
    const authorName = mine
      ? player.name || player.email
      : player.counterpart.name || player.counterpart.email;
    let roleAlign = mine
      ? 'text-right text-kellogg d-inline-block'
      : 'text-left d-inline-block';
    let roleBox = mine
      ? 'float-right text-right bg-kellogg d-inline-block'
      : 'float-left d-inline-block bg-white';
    const roleName = mine
      ? roles[player.rindex]
      : roles[player.counterpart.rindex];

    roleAlign = !author ? 'text-center' : roleAlign;
    roleBox = !author ? 'bg-white' : roleBox;

    return (
      <div
        aria-label={`${authorName}. ${roleName}.`}
        role={role ? role : ''}
        className='clearfix message'>
        <div className={'card mb-3 ' + roleBox} style={{ minWidth: '60%' }}>
          <div className={roleAlign} style={{ padding: '10px 15px' }}>
            {author && (
              <small aria-hidden='true'>
                <b>
                  {authorName} ({roleName})
                </b>
              </small>
            )}
            {author && <br />}
            {this.state.msg}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // const { player } = state;
  // return { player };
  return {};
}

export default connect(mapStateToProps)(Message);
