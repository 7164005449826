import React from 'react'
import { connect } from 'react-redux'

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header'
import { DebriefMenu } from './debrief_menu'

import BargainingImg from '../../assets/bargaining_zone_infographic.png'

class DebriefBargaining extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const { game } = this.props

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                Bargaining Zone
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="mt-3 text-center bg-white">
                <img src={ BargainingImg } className="img-fluid" alt="Bargaining Zone" />
            </div>


        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DebriefBargaining)