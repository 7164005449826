import React from 'react';
import numeral from 'numeral';

class Bargaining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // Bindings
  }

  // componentDidMount() {}

  render() {
    const { players } = this.props.group;
    const energetics = players.find((p) => p.rindex === 1);
    const genrex = players.find((p) => p.rindex === 0);

    const energeticsMin = Math.min(
      energetics.aspiration,
      energetics.reservation
    );
    const genrexMin = Math.min(genrex.aspiration, genrex.reservation);
    const energeticsMax = Math.max(
      energetics.aspiration,
      energetics.reservation
    );
    const genrexMax = Math.max(genrex.aspiration, genrex.reservation);

    const leftBoundary = 100000; //Math.min(energeticsMin, genrexMin)
    const rightBoundary = 2500000; //Math.max(energeticsMax, genrexMax)
    const space = rightBoundary - leftBoundary;

    const energeticsWidth =
      (Math.abs(energetics.reservation - energetics.aspiration) / space) * 100;
    const energeticsLeft =
      (Math.abs(leftBoundary - energeticsMin) / space) * 100;

    // console.log(energeticsLeft, energeticsWidth)

    const genrexWidth =
      (Math.abs(genrex.reservation - genrex.aspiration) / space) * 100;
    const genrexLeft = (Math.abs(leftBoundary - genrexMin) / space) * 100;

    const energeticsBlock =
      energetics.aspiration !== null && energetics.reservation !== null ? (
        <span
          className='badge badge-primary'
          style={{
            position: 'absolute',
            lineHeight: '40px',
            fontSize: '14px',
            top: '-27px',
            left: energeticsLeft + '%',
            width: energeticsWidth + '%',
            backgroundColor: 'rgba(0, 123, 255, .7)',
          }}>
          <span
            style={{
              position: 'absolute',
              top: '-34px',
              left: '-25px',
              color: '#007bff',
            }}>
            $
            {numeral(
              Math.min(energetics.aspiration, energetics.reservation)
            ).format('0.0a')}
            {/* ${ (Math.min(energetics.aspiration, energetics.reservation)) } */}
          </span>
          <span
            style={{
              position: 'absolute',
              top: '-34px',
              left: '70%',
              color: '#007bff',
            }}>
            $
            {numeral(
              Math.max(energetics.aspiration, energetics.reservation)
            ).format('0.0a')}
            {/* ${ (Math.max(energetics.aspiration, energetics.reservation)) } */}
          </span>
          &nbsp;
        </span>
      ) : null;

    const genrexsBlock =
      genrex.aspiration !== null && genrex.reservation !== null ? (
        <span
          className='badge'
          style={{
            position: 'absolute',
            lineHeight: '40px',
            fontSize: '14px',
            top: '-17px',
            left: genrexLeft + '%',
            opacity: 0.7,
            width: genrexWidth + '%',
            backgroundColor: 'rgba(97, 46, 160, .7)',
          }}>
          <span
            style={{
              position: 'absolute',
              bottom: '-34px',
              left: '-25px',
              color: 'rgba(97, 46, 160)',
            }}>
            $
            {numeral(Math.min(genrex.aspiration, genrex.reservation)).format(
              '0.0a'
            )}
          </span>
          <span
            style={{
              position: 'absolute',
              bottom: '-34px',
              left: '70%',
              color: 'rgba(97, 46, 160)',
            }}>
            $
            {numeral(Math.max(genrex.aspiration, genrex.reservation)).format(
              '0.0a'
            )}
          </span>
          &nbsp;
        </span>
      ) : null;

    return (
      <div className='text-center mt-5 pl-3 pr-3'>
        <div
          style={{ position: 'relative', padding: '2px', background: '#333' }}>
          {energeticsBlock}
          {genrexsBlock}
        </div>

        <div className='mt-5 pt-5 text-center'>
          <span
            className='badge'
            style={{
              height: '20px',
              width: '20px',
              lineHeight: '18px',
              borderRadius: '50%',
              backgroundColor: 'rgb(97, 46, 160)',
            }}>
            &nbsp;
          </span>{' '}
          Federico's
          <span
            className='badge badge-primary ml-4'
            style={{
              height: '20px',
              width: '20px',
              lineHeight: '18px',
              borderRadius: '50%',
            }}>
            &nbsp;
          </span>{' '}
          Zephyr
        </div>
      </div>
    );
  }
}
export default Bargaining;
