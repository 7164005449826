import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class DebriefFirst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { game } = this.props;

    if (!game.data) return null;

    const deals = game.data.groups.filter((dyad) => dyad.status === 'deal');

    // Filter by role
    let fedeFirstAvg = { x: 0, y: 0 };
    const fedeFirst = deals
      .filter((dyad) => dyad.items[0].author_role === 0)
      .map((dyad) => {
        fedeFirstAvg.x += dyad.items[0].amount;
        fedeFirstAvg.y += dyad.items[dyad.items.length - 1].amount;
        return {
          x: dyad.items[0].amount,
          y: dyad.items[dyad.items.length - 1].amount,
        };
      });
    fedeFirstAvg = {
      x: fedeFirstAvg.x / fedeFirst.length,
      y: fedeFirstAvg.y / fedeFirst.length,
      marker: { symbol: 'diamond' },
      label: 'Federico Offered First Avg',
    };

    let zephyrFirstAvg = { x: 0, y: 0 };
    const zephyrFirst = deals
      .filter((dyad) => dyad.items[0].author_role === 1)
      .map((dyad) => {
        zephyrFirstAvg.x += dyad.items[0].amount;
        zephyrFirstAvg.y += dyad.items[dyad.items.length - 1].amount;
        return {
          x: dyad.items[0].amount,
          y: dyad.items[dyad.items.length - 1].amount,
        };
      });
    zephyrFirstAvg = {
      x: zephyrFirstAvg.x / zephyrFirst.length,
      y: zephyrFirstAvg.y / zephyrFirst.length,
      marker: { symbol: 'diamond' },
      label: 'Zephyr Offered First Avg',
    };

    console.log({ fedeFirst, fedeFirstAvg, zephyrFirst, zephyrFirstAvg });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          First Offer vs Final Price
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3'>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: { text: 'First Offer vs Final Price ($M)' },
              chart: { type: 'scatter', height: '500px' },
              credits: { enabled: false },
              xAxis: {
                title: { text: 'First Offer' },
                allowDecimals: false,
                min: 0,
                minRange: 2500000,
                labels: {
                  formatter: function () {
                    return (this.value / 1000000).toFixed(1);
                  },
                },
                plotLines: [
                  {
                    color: '#3577F2',
                    width: 2,
                    value: zephyrFirstAvg,
                    label: {
                      text: 'Zephyr Offered First Avg',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    },
                  },
                  {
                    color: 'purple',
                    width: 2,
                    value: fedeFirstAvg,
                    label: {
                      text: 'Federico Offered First Avg',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    },
                  },
                ],
              },
              yAxis: {
                title: { text: 'Final Price' },
                allowDecimals: false,
                min: 0,
                minRange: 2000000,
                labels: {
                  formatter: function () {
                    return (this.value / 1000000).toFixed(1);
                  },
                },
              },
              tooltip: {
                formatter: function () {
                  const p = this.point;
                  return `<b>${
                    p.label || this.series.name
                  }</b><br/>First Offer: $${Highcharts.numberFormat(
                    p.x,
                    0,
                    '.',
                    ','
                  )}<br/>Final Price: $${Highcharts.numberFormat(
                    p.y,
                    0,
                    '.',
                    ','
                  )}`;
                },
              },
              series: [
                {
                  name: 'Zephyr Offered First',
                  color: '#3577F2',
                  marker: {
                    symbol: 'circle',
                    radius: 10,
                  },
                  shape: 'circle',
                  data: [...zephyrFirst, zephyrFirstAvg],
                },
                {
                  name: "Federico's Offered First",
                  color: 'purple',
                  marker: {
                    symbol: 'circle',
                    radius: 10,
                  },
                  data: [...fedeFirst, fedeFirstAvg],
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefFirst);
